import React, { useState, createContext, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Loading from 'app/components/MatxLoading';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export const auth = getAuth(app);
const analytics = getAnalytics(app);

const API_DOMAIN = apiConfig.domain;
const API_VERSION = apiConfig.version;

const setSession = (accessToken) => {
    if (accessToken) {
        axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
};

const AuthContext = createContext({
    user: null,
    method: 'FIREBASE',
    signUp: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    getToken: () => Promise.resolve(),
    logout: () => { },
    resetPassword: () => Promise.resolve()
});

const createUser = async (userInfo) => {
    const response = await axios.post(
        `${API_DOMAIN}${API_VERSION}/CreateUser`,
        userInfo,
    );
    return response.data;
};

const updateUser = async (user, email) => {
    await axios.put(`${API_DOMAIN}${API_VERSION}/UpdateUser`, user, {
        params: { Email: email },
    });
};

const getUserByUserId = async () => {
    try {
        const response = await axios.get(
            `${API_DOMAIN}${API_VERSION}/GetUserByUserId`,
        );
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error);
    }
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const isAuthenticated = user !== null;

    const functionUrl = `${API_DOMAIN}${API_VERSION}/LogAuthEvent`;

    const signInWithEmailAndPassword = async (email, password) => {
        try {
            const loginResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
            const user = loginResponse.user;
            const token = await user.getIdToken();
            setSession(token);

            await getUserProfile(user);

            // Send login event to Azure Function
            await axios.post(functionUrl, {
                eventType: 'login',
                eventData: {
                    email: email,
                    method: 'email',
                    loginTime: new Date().toISOString(),
                },
            });
        } catch (error) {
            // Send login error event to Azure Function
            await axios.post(functionUrl, {
                eventType: 'login_error',
                eventData: {
                    email: email,
                    method: 'email',
                    loginTime: new Date().toISOString(),
                    errorMessage: error.message,
                },
            });
            throw error;
        }
    };

    const signUp = async (userInfo) => {
        try {
            logEvent(analytics, 'sign_up', { method: 'email' });
            const newUser = {
                email: userInfo.email,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                password: userInfo.password,
            };
            await createUser(newUser);
            await signInWithEmailAndPassword(userInfo.email, userInfo.password);
            await axios.post(functionUrl, {
                eventType: 'sign_up_success',
                eventData: {
                    email: userInfo.email,
                    method: 'email',
                    signupTime: new Date().toISOString(),
                },
            });
        } catch (error) {
            await axios.post(functionUrl, {
                eventType: 'sign_up_error',
                eventData: {
                    email: userInfo.email,
                    method: 'email',
                    loginTime: new Date().toISOString(),
                    errorMessage: error.message,
                },
            });
            throw error;
        }
    };

    const resetPassword = (oobCode, newPassword) => {
        return firebase.auth().confirmPasswordReset(oobCode, newPassword);
    };

    const logout = () => {
        firebase.auth().signOut();
        setSession(null);
        setUser(null);

    };

    const hardDeleteLogout = () => {
        setSession(null);
        setUser(null);

    };

    const updateProfilePicture = async (photoURL) => {
        const user = firebase.auth().currentUser;
        await user.updateProfile({ photoURL }).then(() => {
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        });
    };

    const updatedUserDisplayName = async (firstName, lastName) => {
        const user = firebase.auth().currentUser;
        await user.updateProfile({ displayName: `${firstName} ${lastName}` }).then(() => {
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        });
    };

    const getUserProfile = async (FBaccount) => {
        try {
            let argyleUserData = await getUserByUserId();

            if (argyleUserData) {
                setCompoundUser(argyleUserData, FBaccount);
                return;
            }

            await createOrUpdateUser(FBaccount);
            argyleUserData = await getUserByUserId();

            if (argyleUserData) {
                setCompoundUser(argyleUserData, FBaccount);
            }

        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    async function createOrUpdateUser(account) {
        const user = account.uid ? { userId: account.uid } : {};
        await updateUser(user, account.email);
    }

    const setCompoundUser = (argyleUserData, FBaccount) => {
        const user = {
            id: argyleUserData.id,
            firstName: argyleUserData.firstName,
            lastName: argyleUserData.lastName,
            userRole: argyleUserData.userRole,
            displayName: argyleUserData.firstName + ' ' + argyleUserData.lastName,
            email: FBaccount.email,
            uid: FBaccount.uid,
            name: FBaccount.displayName || FBaccount.email,
            avatar: argyleUserData.thumbnailURL || FBaccount.photoURL,
        };
        setUser(user);
    };
    useEffect(() => {
        const unsubscribe = auth.onIdTokenChanged(async (user) => {
            if (user) {
                const tokenResult = await user.getIdTokenResult();
                const expirationTime = new Date(tokenResult.expirationTime).getTime();
                const currentTime = Date.now();
                if (expirationTime - currentTime < 60 * 1000) {
                    await user.getIdToken(true);
                }

                const token = await user.getIdToken();
                setSession(token);
                await getUserProfile(user);
                setLoading(false);
            } else {
                setSession(null);
                setUser(null);
                setLoading(false);
            }
        });

        return () => {

            unsubscribe();
        };
        // eslint-disable-next-line
    }, []);



    if (loading) {
        return <Loading />;
    }

    return (
        <AuthContext.Provider
            value={{
                method: 'FIREBASE',
                signUp,
                signInWithEmailAndPassword,
                logout,
                hardDeleteLogout,
                resetPassword,
                updateProfilePicture,
                updatedUserDisplayName,
                loading,
                user,
                isAuthenticated,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
