import axios from 'axios';
export const CREATE_OBSERVATION = 'CREATE_OBSERVATION';
export const UPDATE_OBSERVATION = 'UPDATE_OBSERVATION';
export const GET_ALL_OBSERVATIONS = 'GET_ALL_OBSERVATIONS';
export const GET_OBSERVATION_BY_ID = 'GET_OBSERVATION_BY_ID';
export const DELETE_OBSERVATION = 'DELETE_OBSERVATION';
export const FETCH_OBSERVATIONS_REQUEST = 'FETCH_OBSERVATIONS_REQUEST';
export const FETCH_OBSERVATIONS_SUCCESS = 'FETCH_OBSERVATIONS_SUCCESS';
export const FETCH_OBSERVATIONS_FAILURE = 'FETCH_OBSERVATIONS_FAILURE';
export const API_ERROR = 'API_ERROR';
export const EXPORT_OBSERVATION = 'EXPORT_OBSERVATION';



const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

const baseurl = `${apiConfig.domain}${apiConfig.version}`;

// Helper function to construct URLs
const constructUrl = (projectId, observationId = '') => {
    return `${baseurl}/project/${projectId}/observation${observationId ? `/${observationId}` : ''}`;
};

// Action Creators
export const createObservation = (projectId, observation) => async (dispatch) => {
    try {
        const url = constructUrl(projectId);
        const response = await axios.post(url, observation);
        dispatch({ type: CREATE_OBSERVATION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error creating observation:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const updateObservation = (projectId, id, observation) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.put(url, observation);
        dispatch({ type: UPDATE_OBSERVATION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error updating observation:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const getAllObservations = (projectId) => async (dispatch) => {
    dispatch({ type: FETCH_OBSERVATIONS_REQUEST });
    try {

        const url = constructUrl(projectId);
        const response = await axios.get(url);
        dispatch({ type: FETCH_OBSERVATIONS_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Error fetching observations:', error);
        dispatch({ type: FETCH_OBSERVATIONS_FAILURE, payload: error.message });
    }
};

export const getObservationById = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.get(url);
        dispatch({ type: GET_OBSERVATION_BY_ID, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error fetching observation:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const batchDeleteObservations = (projectId, arrayOfObvIds) => async (dispatch) => {
    try {
        const url = constructUrl(projectId);
        const response = await axios.delete(url, { data: { observationIds: arrayOfObvIds } });
        dispatch({ type: DELETE_OBSERVATION, payload: response.data });
    } catch (error) {
        console.error('Error deleting observation:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const exportObservations = (projectId, arrayOfObvIds) => async (dispatch) => {
    try {
        let url = constructUrl(projectId);
        url += '/export';
        const response = await axios.post(url, { observationIds: arrayOfObvIds });
        dispatch({ type: EXPORT_OBSERVATION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error creating observation:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};
export const clearObservations = () => ({
    type: 'CLEAR_OBSERVATIONS',
});

// Initial State
const initialState = {
    observations: {}, // Store observations as an object indexed by ID
    loading: false,
    error: null,
    export: null
};

// Reducer
const observationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_OBSERVATIONS':
            return initialState;

        case FETCH_OBSERVATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                observations: action.payload.reduce((acc, obs) => {
                    acc[obs.id] = obs;
                    return acc;
                }, {}) // Convert array to object indexed by ID
            };
        case FETCH_OBSERVATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_OBSERVATION:
            return {
                ...state,
                observations: {
                    ...state.observations,
                    [action.payload.id]: action.payload
                }
            };
        case UPDATE_OBSERVATION:
            return {
                ...state,
                observations: {
                    ...state.observations,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_OBSERVATION: {
            let remainingObservations = { ...state.observations };
            action.payload.results.forEach(result => {
                if (result.status === "Deleted") {
                    delete remainingObservations[result.observationId];
                }
            });

            return {
                ...state,
                observations: remainingObservations
            };
        }
        case EXPORT_OBSERVATION:
            return {
                ...state,
                export: action.payload
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default observationReducer;
