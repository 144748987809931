import axios from 'axios';
// Action Types
export const CREATE_ARIMAGE = 'CREATE_ARIMAGE';
export const UPDATE_ARIMAGE = 'UPDATE_ARIMAGE';
export const GET_ALL_ARIMAGES = 'GET_ALL_ARIMAGES';
export const GET_ARIMAGE_BY_ID = 'GET_ARIMAGE_BY_ID';
export const DELETE_ARIMAGE = 'DELETE_ARIMAGE';
export const FETCH_ARIMAGES_REQUEST = 'FETCH_ARIMAGES_REQUEST';
export const FETCH_ARIMAGES_SUCCESS = 'FETCH_ARIMAGES_SUCCESS';
export const FETCH_ARIMAGES_FAILURE = 'FETCH_ARIMAGES_FAILURE';
export const API_ERROR = 'API_ERROR';



const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

const baseurl = `${apiConfig.domain}${apiConfig.version}`;

// Helper function to construct URLs
const constructUrl = (projectId, arImageId = '') => {
    return `${baseurl}/project/${projectId}/arImage${arImageId ? `/${arImageId}` : ''}`;
};

// Action Creators
export const createARImage = (projectId, arImage) => async (dispatch) => {
    try {
        const url = constructUrl(projectId);
        const response = await axios.post(url, arImage);
        dispatch({ type: CREATE_ARIMAGE, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error creating arImage:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const updateARImage = (projectId, id, arImage) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.put(url, arImage);
        if (response.status === 200) {
            dispatch({ type: UPDATE_ARIMAGE, payload: response.data });
            dispatch(getAllARImages(projectId));
        }

        return response.data;
    } catch (error) {
        console.error('Error updating arImage:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const getAllARImages = (projectId) => async (dispatch) => {
    dispatch({ type: FETCH_ARIMAGES_REQUEST });
    try {
        const url = constructUrl(projectId);
        const response = await axios.get(url);
        dispatch({ type: FETCH_ARIMAGES_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Error fetching arImages:', error);
        dispatch({ type: FETCH_ARIMAGES_FAILURE, payload: error.message });
    }
};

export const getARImageById = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.get(url);
        dispatch({ type: GET_ARIMAGE_BY_ID, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error fetching arImage:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const deleteARImage = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        await axios.delete(url);
        dispatch({ type: DELETE_ARIMAGE, payload: id });
    } catch (error) {
        console.error('Error deleting arImage:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const clearARImages = () => ({
    type: 'CLEAR_ARIMAGES',
});

// Initial State
const initialState = {
    arImages: {}, // Store arImages as an object indexed by ID
    loading: false,
    error: null
};

// Reducer
const arImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_ARIMAGES':
            return initialState;
        case FETCH_ARIMAGES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ARIMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                arImages: action.payload.reduce((acc, obs) => {
                    acc[obs.id] = obs;
                    return acc;
                }, {}) // Convert array to object indexed by ID
            };
        case FETCH_ARIMAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_ARIMAGE:
            return {
                ...state,
                arImages: {
                    ...state.arImages,
                    [action.payload.id]: action.payload
                }
            };
        case UPDATE_ARIMAGE:
            return {
                ...state,
                arImages: {
                    ...state.arImages,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_ARIMAGE:
            const { [action.payload]: _, ...remainingARImages } = state.arImages;
            return {
                ...state,
                arImages: remainingARImages
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default arImageReducer;
