import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllObservations,
    clearObservations
} from 'app/redux/reducers/ObservationReducer';
import {
    getAllARImages,
    clearARImages,
} from 'app/redux/reducers/ARImageReducer';
import {
    getAllLocations,
    clearLocations,
} from 'app/redux/reducers/LocationReducer';


const ObservationContext = createContext(null);

export const ObservationProvider = ({ projectId, children }) => {
    const dispatch = useDispatch();

    const observationsRaw = useSelector(state => state.observations);
    const locationsRaw = useSelector(state => state.locations);
    const arImagesRaw = useSelector(state => state.arImages);

    const observations = useMemo(() => observationsRaw || {}, [observationsRaw]);
    const locations = useMemo(() => locationsRaw || {}, [locationsRaw]);
    const arImages = useMemo(() => arImagesRaw || {}, [arImagesRaw]);

    useEffect(() => {
        if (projectId) {
            dispatch(getAllObservations(projectId));
            dispatch(getAllLocations(projectId));
            dispatch(getAllARImages(projectId));
        }

        return () => {
            dispatch(clearObservations());
            dispatch(clearLocations());
            dispatch(clearARImages());
        };
    }, [dispatch, projectId]);

    const value = useMemo(() => ({
        observations,
        locations,
        arImages,
    }), [observations, locations, arImages]);

    return (
        <ObservationContext.Provider value={value}>
            {children}
        </ObservationContext.Provider>
    );
};

export const useObservation = () => useContext(ObservationContext);
