import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Pagination,
  Card,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ImageCarouselDialog from 'app/views/Argyle/sites/ObservationSection/ImageCarouselDialog';

const IMAGES_PER_PAGE = 20;

const ARImageGalleryPage = ({ images }) => {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselStartIndex, setCarouselStartIndex] = useState(0);
  const [page, setPage] = useState(1);

  const theme = useTheme();

  const handleCloseCarousel = () => {
    setCarouselOpen(false);
  };

  const onImageClick = (index) => {
    setCarouselStartIndex(index);
    setCarouselOpen(true);
  };

  // Collapse to one column on small screens
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const cols = isSmallScreen ? 1 : 3;

  // Calculate pagination
  const totalPages = Math.ceil(images.length / IMAGES_PER_PAGE);

  const sortedImages = React.useMemo(() => {
    return images.slice().sort((b, a) => {
      const aTime =
        a.metadata?.timeStamp &&
        !isNaN(new Date(a.metadata.timeStamp).getTime())
          ? new Date(a.metadata.timeStamp).getTime()
          : 0;
      const bTime =
        b.metadata?.timeStamp &&
        !isNaN(new Date(b.metadata.timeStamp).getTime())
          ? new Date(b.metadata.timeStamp).getTime()
          : 0;
      return aTime - bTime;
    });
  }, [images]);

  const paginatedImages = sortedImages.slice(
    (page - 1) * IMAGES_PER_PAGE,
    page * IMAGES_PER_PAGE,
  );

  // Group the sorted images by day
  const groupedImages = [];
  let currentDate = null;
  let counter = 0;
  paginatedImages.forEach((image) => {
    const timeStamp =
      image.metadata?.timeStamp &&
      !isNaN(new Date(image.metadata.timeStamp).getTime())
        ? new Date(image.metadata.timeStamp)
        : null;
    const dateKey = timeStamp ? format(timeStamp, 'MM/dd/yyyy') : 'N/A';
    if (dateKey !== currentDate) {
      groupedImages.push({
        date: dateKey,
        images: [{ image, localIndex: counter }],
      });
      currentDate = dateKey;
    } else {
      groupedImages[groupedImages.length - 1].images.push({
        image,
        localIndex: counter,
      });
    }
    counter++;
  });

  const handleDownload = (e, image) => {
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = image.augmentedUrl;
    link.download = image.augmentedUrl.split('/').pop();
    link.click();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box p={3}>
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <PhotoLibraryIcon fontSize="large" />
          <Typography variant="h2" style={{ marginLeft: '8px' }}>
            AR Photo Gallery
          </Typography>
        </Box>
      </Card>
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        {groupedImages.map((group, groupIndex) => (
          <Box key={groupIndex} mb={4}>
            <Typography variant="h6" gutterBottom>
              {group.date}
            </Typography>
            <ImageList cols={cols} gap={16}>
              {group.images.map(({ image, localIndex }) => (
                <ImageListItem
                  key={image.id || localIndex}
                  sx={{ cursor: 'pointer', position: 'relative' }}
                  onClick={() =>
                    onImageClick((page - 1) * IMAGES_PER_PAGE + localIndex)
                  }
                >
                  <img
                    src={image.arThumbnailUrl}
                    alt={`Thumbnail for ${image.id}`}
                    loading="lazy"
                    style={{
                      width: '100%',
                      display: 'block',
                      objectFit: 'contain',
                    }}
                  />
                  <ImageListItemBar
                    title={
                      image.metadata?.timeStamp &&
                      !isNaN(new Date(image.metadata.timeStamp).getTime())
                        ? format(
                            new Date(image.metadata.timeStamp),
                            'MM/dd/yyyy',
                          )
                        : 'N/A'
                    }
                    actionIcon={
                      <IconButton
                        onClick={(e) => handleDownload(e, image)}
                        sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        ))}
        {totalPages > 1 && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}
        <ImageCarouselDialog
          open={carouselOpen}
          onClose={handleCloseCarousel}
          images={sortedImages}
          initialIndex={carouselStartIndex}
        />
      </Card>
    </Box>
  );
};

ARImageGalleryPage.propTypes = {
  images: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ARImageGalleryPage;
