import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite, getSiteUsers, clearSite } from 'app/redux/actions/SiteActions';
import { getOrgById, clearOrg, getAllUsersByOrgId, clearOrgUserList } from 'app/redux/actions/OrgActions';
import { determineHighestPrivilege } from 'app/utils/utils';
import useAuth from 'app/hooks/useAuth';

const SiteContext = createContext(null);

export const SiteProvider = ({ siteId, children }) => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    // Redux state selections
    const siteInfo = useSelector(state => state.site.site);
    const siteUsers = useSelector(state => state.site.siteUsersList);
    const error = useSelector(state => state.site.error);
    const unauthorized = useSelector(state => state.site.unauthorized);
    const org = useSelector(state => state.orgs.org);

    const [highestPrivilege, setHighestPrivilege] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch site info and site users
    useEffect(() => {
        const fetchSiteData = async () => {
            try {
                setLoading(true);
                await dispatch(getSite(siteId));
                await dispatch(getSiteUsers({ siteId }));
            } catch (err) {
                console.error('Error fetching site data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchSiteData();

        return () => {
            dispatch(clearSite());
        };
    }, [siteId, dispatch]);

    // Memoized current user site role
    const currentUserSiteRole = useMemo(() => {
        if (user && siteUsers) {
            const foundUser = siteUsers.find(su => su.userId === user.uid);
            return foundUser?.siteRole || 'No Role';
        }
        return 'No Role';
    }, [user, siteUsers]);

    // Calculate the highest privilege for the user
    useEffect(() => {
        if (user && siteUsers) {
            const userRoles = {
                userRole: user.userRole,
                orgRole: org?.OrgRole || 0,
                siteRole: currentUserSiteRole,
            };
            setHighestPrivilege(determineHighestPrivilege(userRoles));
        }
    }, [user, siteUsers, org, currentUserSiteRole]);

    // Fetch organization data if not a demo site
    useEffect(() => {
        if (!siteInfo || siteInfo.isDemo) {
            return;
        }

        if (siteInfo.OrgId) {
            dispatch(getOrgById(parseInt(siteInfo.OrgId, 10)));
            dispatch(getAllUsersByOrgId(siteInfo.OrgId));
        }

        return () => {
            dispatch(clearOrg());
            dispatch(clearOrgUserList());
        };
    }, [siteInfo, dispatch]);

    // Memoize context value to avoid unnecessary renders
    const contextValue = useMemo(() => ({
        siteInfo,
        highestPrivilege,
        loading,
        error,
        unauthorized,
        org,
        currentUserSiteRole,
    }), [siteInfo, highestPrivilege, loading, error, unauthorized, org, currentUserSiteRole]);

    return (
        <SiteContext.Provider value={contextValue}>
            {children}
        </SiteContext.Provider>
    );
};

export const useSite = () => useContext(SiteContext);
