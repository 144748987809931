import axios from 'axios';

// Action Types
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const GET_ALL_COLLECTIONS = 'GET_ALL_COLLECTIONS';
export const GET_COLLECTION_BY_ID = 'GET_COLLECTION_BY_ID';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const FETCH_COLLECTIONS_REQUEST = 'FETCH_COLLECTIONS_REQUEST';
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE';
export const API_ERROR = 'API_ERROR';


const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

const baseurl = `${apiConfig.domain}${apiConfig.version}`;

// Helper function to construct URLs
const constructUrl = (projectId, observationId = '') => {
    return `${baseurl}/project/${projectId}/collection${observationId ? `/${observationId}` : ''}`;
};

// Action Creators
export const createCollection = (projectId, collection) => async (dispatch) => {
    try {
        const url = constructUrl(projectId);
        const response = await axios.post(url, collection);
        dispatch({ type: CREATE_COLLECTION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error creating collection:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const updateCollection = (projectId, id, collection) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.put(url, collection);
        dispatch({ type: UPDATE_COLLECTION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error updating collection:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const getAllCollections = (projectId) => async (dispatch) => {
    dispatch({ type: FETCH_COLLECTIONS_REQUEST });
    try {
        const url = constructUrl(projectId);
        const response = await axios.get(url);
        dispatch({ type: FETCH_COLLECTIONS_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Error fetching collections:', error);
        dispatch({ type: FETCH_COLLECTIONS_FAILURE, payload: error.message });
    }
};

export const getCollectionById = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.get(url);
        dispatch({ type: GET_COLLECTION_BY_ID, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error fetching collection:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const deleteCollection = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        await axios.delete(url);
        dispatch({ type: DELETE_COLLECTION, payload: id });
    } catch (error) {
        console.error('Error deleting collection:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

// Initial State
const initialState = {
    collections: {}, // Store collections as an object indexed by ID
    loading: false,
    error: null
};

// Reducer
const collectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COLLECTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_COLLECTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                collections: action.payload.reduce((acc, obs) => {
                    acc[obs.id] = obs;
                    return acc;
                }, {}) // Convert array to object indexed by ID
            };
        case FETCH_COLLECTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_COLLECTION:
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.id]: action.payload
                }
            };
        case UPDATE_COLLECTION:
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_COLLECTION:
            const { [action.payload]: _, ...remainingCollections } = state.collections;
            return {
                ...state,
                collections: remainingCollections
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default collectionReducer;