import React from 'react';
import { useSite } from 'app/contexts/SiteContext.js';
import { useObservation } from 'app/contexts/ObservationContext';
import { Breadcrumb } from 'app/components';
import ARImageGallery from './ARImageGallery';
import ArgyleSnackbar from 'app/components/AgyleComponents/ArgyleSnackbar';

const ARImagePage = () => {
  const { siteInfo, org } = useSite();
  const { arImages } = useObservation();

  const arImagesArray = Object.values(arImages.arImages) || [];

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          ...(org?.Id
            ? [
                {
                  name: org.Name,
                  path: `/org/details/${org.Id}`,
                },
                { name: siteInfo.siteName, path: `/site/${siteInfo.id}` },
                {
                  name: 'AR Photo Gallery',
                  path: `/site/${siteInfo.id}/observations/ar-image-gallery`,
                },
              ]
            : [
                { name: siteInfo.siteName, path: `/site/${siteInfo.id}` },
                {
                  name: 'AR Photo Gallery',
                  path: `/site/${siteInfo.id}/observations/ar-image-gallery`,
                },
              ]),
        ]}
      />

      <ARImageGallery images={arImagesArray} projectId={siteInfo.id} />

      <ArgyleSnackbar />
    </div>
  );
};

export default ARImagePage;
