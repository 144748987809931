
import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import SiteLayout from './SiteLayout';
import ARImagePage from './ARImageSection/ARImagePage';
import ObservationRoutesWrapper from './ObservationSection/ObservationRoutesWrapper';

const SiteDetails = Loadable(lazy(() => import("./SiteDetails")));
const ObservationPage = Loadable(lazy(() => import("./ObservationSection/ObservationPage")));
const QRPage = Loadable(lazy(() => import("./QRSection/QRPage")));
const ModelSection = Loadable(lazy(() => import("./ModelSection/ModelPage")));
const ObservationDetailsSection = Loadable(lazy(() => import("./ObservationSection/ObservationDetails")));


const siteRoutes = [
    {
        path: '/site/:siteId',
        element: <SiteLayout />, // SiteProvider is provided here
        children: [
            {
                path: 'details',
                element: <SiteDetails />,
            },
            {
                path: '', // default route: can show SiteDetails or a landing component
                element: <SiteDetails />,
            },
            {
                path: 'observations',
                element: <ObservationRoutesWrapper />, // This applies the ObservationProvider
                children: [
                    {
                        path: '',
                        element: <ObservationPage />,
                    },
                    {
                        path: ':observationId',
                        element: <ObservationDetailsSection />,
                    },
                    {
                        path: 'ar-image-gallery',
                        element: <ARImagePage />,
                    },
                ],
            },
            {
                path: 'qr',
                element: <QRPage />,
            },
            {
                path: 'qr/:qrId',
                element: <QRPage />,
            },
            {
                path: 'models',
                element: <ModelSection />,
            },
        ],
    },
];

export default siteRoutes;
