import { combineReducers } from 'redux'
import snackbarReducer from './snackbarReducer'
import SiteReducer from './SiteReducer'
import CardReducer from './CardReducer'
import UserReducer from './UserReducer'
import FileReducer from './FileReducer'
import QRReducer from './QRReducer'
import orgReducer from './OrgReducer'
import firebaseReducer from './FirebaseAuthReducer'
import NavigationReducer from './NavigationReducer'
import licenseReducer from './LicenseReducer'
import observationReducer from './ObservationReducer'
import locationReducer from './LocationReducer'
import collectionReducer from './CollectionReducer'
import arImageReducer from './ARImageReducer'


const RootReducer = combineReducers({
    snackbar: snackbarReducer,
    navigations: NavigationReducer,
    site: SiteReducer,
    card: CardReducer,
    users: UserReducer,
    files: FileReducer,
    QRData: QRReducer,
    orgs: orgReducer,
    firebaseAuth: firebaseReducer,
    license: licenseReducer,
    observations: observationReducer,
    locations: locationReducer,
    collections: collectionReducer,
    arImages: arImageReducer
})

export default RootReducer
