import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid2 as Grid,
  CardMedia,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { useObservation } from 'app/contexts/ObservationContext';
import { useSelector } from 'react-redux';

const ImageCarouselDialog = ({ open, onClose, images, initialIndex = 0 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isLoaded, setIsLoaded] = useState(false);
  // Defaulting to augmented photo view
  const [useARImage, setUseARImage] = useState(true);
  const { locations } = useObservation();
  const locationsArray = Object.values(locations.locations || {});

  const siteUsers = useSelector((state) => state.site.siteUsersList);

  useEffect(() => {
    if (open) {
      setCurrentIndex(initialIndex);
      setIsLoaded(false);
      setUseARImage(true); // Reset to augmented view on open
    }
  }, [open, initialIndex]);

  const currentImage = useMemo(
    () => images[currentIndex],
    [images, currentIndex],
  );

  const handlePrev = useCallback(() => {
    if (images.length <= 1) return;
    setIsLoaded(false);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
    setUseARImage(true); // Use augmented view on navigation
  }, [images.length]);

  const handleNext = useCallback(() => {
    if (images.length <= 1) return;
    setIsLoaded(false);
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
    setUseARImage(true); // Use augmented view on navigation
  }, [images.length]);

  const handleDownloadImage = useCallback(() => {
    const imageUrl = useARImage
      ? currentImage.augmentedUrl
      : currentImage.worldUrl;
    if (!imageUrl) return;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageUrl.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [currentImage, useARImage]);

  const toggleImageView = useCallback(() => {
    setIsLoaded(false);
    setUseARImage((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!open) return;
    const handleKeyDown = (e) => {
      if (images.length <= 1) return;
      if (e.key === 'ArrowLeft') {
        handlePrev();
      } else if (e.key === 'ArrowRight') {
        handleNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open, handlePrev, handleNext, images.length]);

  if (!images || images.length === 0) return null;

  const getLocationName = (locations, id) => {
    const location = locations.find((loc) => loc.id === id);
    return location ? location.name : 'Unknown Location';
  };
  const siteUserEmail = (userId) => {
    const foundUser = siteUsers.find((user) => user.id === userId);
    return foundUser?.email || 'Not a project member.';
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen={isMobile}>
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }}>
        {currentImage?.metadata?.timeStamp
          ? format(new Date(currentImage.metadata.timeStamp), 'MM/dd/yyyy')
          : 'Image'}

        <IconButton
          aria-label="download"
          onClick={handleDownloadImage}
          sx={{
            position: 'absolute',
            right: 96,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <DownloadIcon />
        </IconButton>

        <IconButton
          aria-label="toggle image view"
          onClick={toggleImageView}
          sx={(theme) => ({
            position: 'absolute',
            right: 48,
            top: 8,
            color: useARImage
              ? theme.palette.common.white
              : theme.palette.grey[500],
            backgroundColor: useARImage
              ? theme.palette.secondary.main
              : 'transparent',
            '&:hover': {
              backgroundColor: useARImage
                ? theme.palette.secondary
                : 'rgba(0, 0, 0, 0.08)',
            },
          })}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            AR
          </Typography>
        </IconButton>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 2,
          overflowY: 'auto', // Changed to allow scrolling
        }}
      >
        <Grid container direction="column" spacing={2}>
          {/* Image Section */}
          <Grid>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <IconButton
                onClick={handlePrev}
                sx={{ position: 'absolute', left: 0 }}
                disabled={images.length <= 1}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                {!isLoaded && (
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex={1}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <CardMedia
                  key={`${currentImage.augmentedUrl}-${useARImage}`}
                  component="img"
                  image={
                    useARImage
                      ? currentImage.augmentedUrl
                      : currentImage.worldUrl
                  }
                  alt={`Image ${currentIndex + 1}`}
                  onLoad={() => setIsLoaded(true)}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: 500,
                    objectFit: 'contain',
                    opacity: isLoaded ? 1 : 0,
                    transition: 'opacity 0.5s ease-in-out',
                  }}
                />
              </Box>
              <IconButton
                onClick={handleNext}
                sx={{ position: 'absolute', right: 0 }}
                disabled={images.length <= 1}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Grid>
          {/* Metadata Section */}
          {currentImage.metadata && (
            <Grid>
              <Box mt={2}>
                <Typography variant="h6">Photo Details</Typography>
                <Typography variant="body2">
                  <strong>Timestamp:</strong>{' '}
                  {currentImage.metadata.timeStamp
                    ? format(
                        new Date(currentImage.metadata.timeStamp),
                        'MM/dd/yyyy HH:mm:ss',
                      )
                    : 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Geographic coordinates:</strong>{' '}
                  {currentImage.metadata.latitude !== undefined &&
                  currentImage.metadata.longitude !== undefined
                    ? `Latitude: ${currentImage.metadata.latitude}, Longitude: ${currentImage.metadata.longitude}`
                    : 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Project Location</strong>{' '}
                  {currentImage.metadata.locationId !== undefined
                    ? getLocationName(locationsArray, currentImage.locationId)
                    : 'N/A'}
                </Typography>

                <Typography variant="body2">
                  <strong>Device Type:</strong>{' '}
                  {currentImage.metadata.deviceType || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Created By:</strong>{' '}
                  {siteUserEmail(currentImage.metadata.createdBy)}
                </Typography>
                <Typography variant="body2">
                  <strong>Position:</strong>{' '}
                  {currentImage.metadata.sitePositionX !== undefined &&
                  currentImage.metadata.sitePositionY !== undefined &&
                  currentImage.metadata.sitePositionZ !== undefined
                    ? `X: ${currentImage.metadata.sitePositionX}, Y: ${currentImage.metadata.sitePositionY}, Z: ${currentImage.metadata.sitePositionZ}`
                    : 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ImageCarouselDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      augmentedUrl: PropTypes.string.isRequired,
      worldUrl: PropTypes.string,
      metadata: PropTypes.shape({
        timeStamp: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date),
        ]),
        createdBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        deviceType: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        sitePositionX: PropTypes.number,
        sitePositionY: PropTypes.number,
        sitePositionZ: PropTypes.number,
      }),
    }),
  ).isRequired,
  initialIndex: PropTypes.number,
};

export default ImageCarouselDialog;
