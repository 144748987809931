import React from "react";
import { Snackbar, Alert } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "app/redux/actions/SnackbarActions";

const AgyleSnackbar = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const handleCloseSnackbar = () => {
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={snackbar.status}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
export default AgyleSnackbar;
