import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ObservationProvider } from 'app/contexts/ObservationContext';

const ObservationRoutesWrapper = () => {
  const { siteId } = useParams();

  return (
    <ObservationProvider projectId={siteId}>
      <Outlet />
    </ObservationProvider>
  );
};

export default ObservationRoutesWrapper;
