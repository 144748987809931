import axios from 'axios';

// Action Types
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS';
export const GET_LOCATION_BY_ID = 'GET_LOCATION_BY_ID';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const API_ERROR = 'API_ERROR';


const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

const baseurl = `${apiConfig.domain}${apiConfig.version}`;

// Helper function to construct URLs
const constructUrl = (projectId, locationId = '') => {
    return `${baseurl}/project/${projectId}/location${locationId ? `/${locationId}` : ''}`;
};

// Action Creators
export const createLocation = (projectId, location) => async (dispatch) => {
    try {
        const url = constructUrl(projectId);
        const response = await axios.post(url, location);
        dispatch({ type: CREATE_LOCATION, payload: response.data });
        dispatch(getAllLocations(projectId));
        return response.data;
    } catch (error) {
        console.error('Error creating location:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const updateLocation = (projectId, id, location) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.put(url, location);
        dispatch({ type: UPDATE_LOCATION, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error updating location:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const getAllLocations = (projectId) => async (dispatch) => {
    dispatch({ type: FETCH_LOCATIONS_REQUEST });
    try {
        const url = constructUrl(projectId);
        const response = await axios.get(url);
        dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Error fetching locations:', error);
        dispatch({ type: FETCH_LOCATIONS_FAILURE, payload: error.message });
    }
};

export const getLocationById = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        const response = await axios.get(url);
        dispatch({ type: GET_LOCATION_BY_ID, payload: response.data });
        return response.data;
    } catch (error) {
        console.error('Error fetching location:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const deleteLocation = (projectId, id) => async (dispatch) => {
    try {
        const url = constructUrl(projectId, id);
        await axios.delete(url);
        dispatch({ type: DELETE_LOCATION, payload: id });
    } catch (error) {
        console.error('Error deleting location:', error);
        dispatch({ type: API_ERROR, payload: error.message });
        throw error;
    }
};

export const clearLocations = () => ({
    type: 'CLEAR_LOCATIONS',
});

// Initial State
const initialState = {
    locations: {}, // Store locations as an object indexed by ID
    loading: false,
    error: null
};

// Reducer
const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_LOCATIONS':
            return initialState;
        case FETCH_LOCATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                locations: action.payload.reduce((acc, obs) => {
                    acc[obs.id] = obs;
                    return acc;
                }, {}) // Convert array to object indexed by ID
            };
        case FETCH_LOCATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_LOCATION:
            return {
                ...state,
                locations: {
                    ...state.locations,
                    [action.payload.id]: action.payload
                }
            };
        case UPDATE_LOCATION:
            return {
                ...state,
                locations: {
                    ...state.locations,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_LOCATION:
            const { [action.payload]: _, ...remainingLocations } = state.locations;
            return {
                ...state,
                locations: remainingLocations
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default locationReducer;
