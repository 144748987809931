import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
  Paper,
  Typography,
  styled,
  Container,
  Grid2 as Grid,
} from '@mui/material';
import Loading from 'app/components/MatxLoading';
import { SiteProvider, useSite } from 'app/contexts/SiteContext';
import { Box } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

// This component assumes it's rendered within SiteProvider.
const SiteLayoutContent = () => {
  const site = useSite();

  if (!site) {
    console.error(
      'Site context is null. Ensure your component is wrapped with SiteProvider.',
    );
    return (
      <StyledPaper elevation={3}>
        <Typography color="error">Site context is not available.</Typography>
      </StyledPaper>
    );
  }

  const { error, unauthorized, loading } = site;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <StyledPaper elevation={3}>
        <Typography color="error">
          There was an error loading the site. Please try again later.
        </Typography>
      </StyledPaper>
    );
  }

  if (unauthorized) {
    return (
      <StyledPaper elevation={3}>
        <Typography color="error">
          You are not authorized to view this site.
        </Typography>
      </StyledPaper>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {site.siteInfo.siteName}
          </Typography>
          <Outlet />
        </Box>
      </Grid>
    </Container>
  );
};

const SiteLayout = () => {
  const { siteId } = useParams();

  return (
    <SiteProvider siteId={siteId}>
      <SiteLayoutContent />
    </SiteProvider>
  );
};

export default SiteLayout;
