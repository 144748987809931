import { authRoles } from './auth/authRoles'
import DashboardIcon from '@mui/icons-material/Dashboard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import QrCodeIcon from '@mui/icons-material/QrCode';

export const navigations = [

    {
        name: 'Dashboard',
        path: '/',
        icon: 'home',
        auth: authRoles.user,
    },

]

export const resourceNavigations = [
    {
        name: 'Resources',
        auth: authRoles.user,
        children: [

            {
                name: 'Knowledge Base',
                icon: 'launch',
                type: 'extLink',
                path: 'https://argylebuild.notion.site/50573beac8d14e8fbd3db103b6294b87?v=abfecdf2169a458e8f75dff73bde777f&pvs=4',
                auth: authRoles.user,
            },
            {
                name: 'Revit & Navisworks Plugin',
                icon: 'extension',
                type: 'extLink',
                path: 'https://www.argyle.build/download',
                auth: authRoles.user,
            },
            {
                name: 'Get the AR App',
                icon: 'star',
                type: 'extLink',
                path: 'https://argylebuild.notion.site/Get-the-AR-App-8fec21f155ee4c19b1321dd8381a8800',
                auth: authRoles.user,
            },
        ]
    },
]

export const AdminNavigations = [
    {
        label: 'Management',
        type: 'label',
        auth: authRoles.admin,
    }, {
        name: 'Admin',
        children: [
            {
                name: 'Organizations',
                path: '/admin/manage-orgs',
                icon: 'group_work',
                auth: authRoles.admin,
            },
            {
                name: 'Projects',
                path: '/admin/manage-sites',
                icon: 'dashboard',
                auth: authRoles.admin,
            },
            {
                name: 'Users',
                path: '/admin/manage-users',
                auth: authRoles.admin,
                icon: 'person',
            }
        ],
    },
]

export const siteNavigations = (siteId) => [
    {
        label: 'Project Navigation',
        type: 'label',
        auth: authRoles.user,
    }, {
        name: 'Project Details',
        path: `/site/${siteId}`,
        icon: <DashboardIcon />,
        auth: authRoles.user,
    },
    {
        name: 'Observations',
        path: `/site/${siteId}/observations`,
        icon: <VisibilityIcon />,
        auth: authRoles.user,
    },
    {
        name: 'AR Photo Gallery',
        path: `/site/${siteId}/observations/ar-image-gallery`,
        icon: <PhotoLibraryIcon />,
        auth: authRoles.user,
    },
    {
        name: 'QR Codes',
        path: `/site/${siteId}/qr`,
        icon: <QrCodeIcon />,
        auth: authRoles.user,
    },
    {
        name: 'Models',
        path: `/site/${siteId}/models`,
        icon: <ModelTrainingIcon />,
        auth: authRoles.user,
    },



]